<template>
  <div>
    <section v-if="getAllBlog.count === 0" class="blog-block">
        <header class="no-content">
          <h1>{{ $store.getters.GET_LANG ? 'Комментарии отсутствуют' : 'No comments' }}</h1>
        </header>
    </section>
    <section v-for="(blogBlock, i) in getAllBlog.posts" :key="i">
      <router-link v-if="from === 'admin' || (blogBlock.status !== 'Новый' && from === 'client')" :to="`/detail_blog/${Number(blogBlock.id)}?memberId=${id || idUser}`" class="blog-content">

      <article class="comment_card comment-list" :class="{ 'border-bottom' : from === 'admin'}">
        <p class="card__rubric" v-if="blogBlock.rubric">
          {{ $store.getters.GET_LANG ? blogBlock.rubric : blogBlock.rubric_eng }}
        </p>
        <div class="author">
          <img :src="blogBlock.photo">
          <div class="info">
            <p class="name"> {{ ($store.getters.GET_LANG ? blogBlock.full_name : blogBlock.full_name_eng) }} </p>
            <p class="date"> {{ toLocateMonth(blogBlock.date)}} </p>
          </div>
        </div>
        <p class="comment_card-title" v-trim="2"> {{ $store.getters.GET_LANG ? blogBlock.title : blogBlock.title_eng }} </p>
      </article>
        <div :class="{ 'content-sb' : from === 'admin','margin-line' : from === 'admin'}">
          <p class="blog-info__views">
            <svg viewBox="0 0 22 13" fill="#5A79A8" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M21.1891 5.88789C18.0009 2.05421 14.5729 0.16914 11 0.259968C5.23554 0.419835 1.26752 5.68707 1.10134 5.91099C1.09367 5.92149 1.08838 5.93304 1.08176 5.94407C1.07621 5.95247 1.07118 5.96113 1.06615 5.97032C1.05424 5.99132 1.04419 6.01232 1.03572 6.03411C1.03175 6.04461 1.02831 6.05511 1.02487 6.06561C1.01826 6.08635 1.0127 6.10708 1.00873 6.12809C1.00609 6.14174 1.0045 6.15539 1.00318 6.1693C1.00132 6.18715 1 6.205 1 6.22311C1 6.24096 1.00132 6.25881 1.00318 6.27666C1.00476 6.29058 1.00635 6.30397 1.00873 6.31788C1.0127 6.33888 1.01826 6.35962 1.02487 6.38036C1.02831 6.39086 1.03175 6.40136 1.03572 6.41186C1.04419 6.43364 1.05451 6.45465 1.06615 6.47565C1.07118 6.48457 1.07594 6.49297 1.08176 6.5019C1.08838 6.51266 1.09367 6.52447 1.10134 6.53497C1.26752 6.75916 5.23554 12.0261 11 12.186C11.0891 12.1884 11.1778 12.1897 11.2664 12.1897C14.7431 12.1897 18.08 10.2962 21.1891 6.55807C21.2709 6.4599 21.308 6.34098 21.3077 6.22311C21.308 6.10498 21.2709 5.98607 21.1891 5.88789ZM11.036 11.1367C6.55407 11.0157 3.12928 7.32591 2.20396 6.22311C3.12928 5.12032 6.55381 1.43023 11.036 1.30921C14.1641 1.2281 17.2026 2.88138 20.0857 6.22311C17.2029 9.56511 14.1673 11.2265 11.036 11.1367Z"
                stroke="#5A79A8" stroke-width="0.461538"/>
              <path
                d="M11.4177 2.76855C9.49635 2.76855 7.93359 4.31893 7.93359 6.22474C7.93359 8.13082 9.49635 9.68119 11.4177 9.68119C13.339 9.68119 14.9017 8.13082 14.9017 6.22474C14.9017 4.31893 13.339 2.76855 11.4177 2.76855ZM11.4177 8.63116C10.0801 8.63116 8.99202 7.55172 8.99202 6.22474C8.99202 4.89802 10.0801 3.81859 11.4177 3.81859C12.7552 3.81859 13.8433 4.89802 13.8433 6.22474C13.8433 7.55146 12.7552 8.63116 11.4177 8.63116Z"
                stroke="#5A79A8" stroke-width="0.461538"/>
            </svg>
            {{ blogBlock.view_count }}
          </p>
          <p v-if="from === 'admin'" class="text-info-status text-info-status__green"
             :style="`background-color: ${setColor(blogBlock.status)}`">
            {{blogBlock.status}}
          </p>
        </div>
      </router-link>
    </section>
<!--    <pagination v-if="getListNews.length !== 0" class="pagination"-->
<!--                :per-page="$store.getters.getPaginationLimit"-->
<!--                :current="$store.getters.getPaginationCurrentPage"-->
<!--                :total="$store.getters.getPaginationTotal"-->
<!--                @page-changed="getPage"/>-->
    <pagination class="pagination" :current="currentPage" :total="countPage" :per-page="3" @page-changed="getPage"/>

  </div>
</template>

<script>
import Pagination from '@/components/Pagination'

export default {
  name: 'Blog',
  props: {
    id: {
      type: String
      // default: 0
    },
    from: {
      type: String,
      default: 'client'
    },
    link: {
      type: String,
      required: false,
      default: '/detail_blog'
    }
  },
  data () {
    // console.log(Number(atob(localStorage.getItem('token').split('==')[0]).split(';')[0]))
    return {
      idUser: this.$route.query.idUser || this.id,
      currentPage: 1,
      countPage: 0,
      statusListColor: new Map([
        ['Черновик', 'grey'],
        ['Новый', '#EB494E'],
        ['Опубликован', '#8ABD1F'],
        ['На проверке', '#246CB7'],
        ['Отклонен', '#EB494E']
      ])
    }
  },

  computed: {
    getTotalPage () {
      return 1
      // return this.$store.getters.getContent.total ? this.$store.getters.getContent.total : 40
    },
    getAllBlog () {
      return this.$store.getters.GET_LIST_POSTS
    },
    getMemberInfo () {
      return this.$store.getters.GET_MEMBER
    }
  },
  watch: {
    getAllBlog () {
      this.setBlog()
    }
  },
  methods: {
    getPage (page) {
      this.currentPage = page
      this.$emit('getPaginatedBlogs', this.currentPage)
    },
    setBlog () {
      this.countPage = this.$store.getters.GET_LIST_POSTS.total
    },
    toLocateMonth (date) {
      return (new Date(date).toLocaleString('ru', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      })).slice(0, -3)
    },
    // возвращает цвет статуса
    setColor (status) {
      return this.statusListColor.get(status)
    }
  },
  mounted () {
    // this.$store.dispatch('apiMemberPost', this.$route.query.idUser)
  },
  components: {
    // 'pagination-block': Pagination
    Pagination
  }
}
</script>

<style scoped lang="scss">
  .blog-block {
    padding: 1.25rem 1.875rem;
    border: 1px solid #EBF3FF;
    box-shadow: 0 15px 25px rgba(189, 205, 221, 0.35);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 2rem;

  }

  .margin-line {
    margin-top: 1rem;
  }

  .border-bottom {
    border-bottom: 1px solid #D5D6D9;
  }
  .no-content {
    > h1 {
      font-size: .875rem;
      font-weight: 600;
    }
  }
  .blog-content {
    font-style: normal;
    font-weight: normal;
    font-size: .875rem;
    line-height: 1.57;
    color: rgba(23, 23, 23, 0.8);
    width: 100%;

    padding: 1.25rem 1.875rem;
    border: 1px solid #EBF3FF;
    box-shadow: 0 15px 25px rgba(189, 205, 221, 0.35);
    border-radius: 8px;
    display: block;
    margin-bottom: 2rem;
    /*margin-bottom: 1rem;*/

    & h1 {
      font-weight: 600;
      color: #1F3245;
      margin-bottom: 1.375rem;
    }
  }

  .blog-info {
    display: flex;
    &__views {
      font-size: .8125rem;
      line-height: 1.23;
      font-weight: 600;
      color: #5A79A8;
      vertical-align: baseline;

      svg {
        height: .8125rem;
        margin: -.1rem .5rem 0 0;
      }
    }
  }

  .pagination {
    margin-top: 2.5rem;
  }

  .comment_card {
    padding-bottom: 1.25rem;

    .card__rubric{
      padding: .125rem .625rem;
      background: #EEF4FA;
      border-radius: .1875rem;

      text-transform: uppercase;
      color: #306292;
      font-weight: 600;
      font-size: .8125rem;
      line-height: 1rem;
      margin-bottom: .86rem;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: fit-content;
    }

    .author {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: .5rem;

      img {
        width: 3.75rem;
        height: 3.75rem;
        object-fit: cover;
        object-position: center;
        border-radius: 50%;
        margin-right: 1.25rem;
      }

      .info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .name {
          font-weight: bold;
          font-size: 1rem;
          line-height: 1.5rem;
          color: #1F3245;
        }

        .date {
          font-size: .875rem;
          line-height: 1.375rem;
          color: #5E686C;
          opacity: .8;
        }
      }
    }

    &-title {
      font-weight: 600;
      font-size: .875rem;
      line-height: 1.375rem;
      min-height: 1.1rem;
      color: rgba(23, 23, 23, 0.8);
    }
  }

  @media screen and (max-width: 768px) {
    .blog-content {
      width: 100%;
    }
  }

  @media screen and (max-width: 420px) {
    .blog-block {
      padding: 1.25rem 1.38rem;
    }
    .blog-content {
      width: 100%;
    }
  }
</style>
